import React from 'react'
import PropTypes from 'prop-types'

import TracklineBackground from '@objects/tracklineBackground'
import useStaticModule from '@hooks/useStaticModule'
import Container from '@objects/container'
import Copy from '@objects/copy'

import { useIntl } from 'react-intl'
import Headline from '@objects/headline'
import ContactForm from '@components/forms/contactForm/index'

function Impressum({ data, pageContext }) {
  const intl = useIntl()
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)

  const copyContent = getStaticFieldValue(
    'contact.report',
    'contact.report.copy'
  )

  const infotexts = {
    type: 'contact',
    copy: getStaticFieldValue(
      'contact.report',
      'contact.report.description.info'
    ),
    email: getStaticFieldValue('contact.report', 'contact.report.email.info'),
    terms: getStaticFieldValue('contact.report', 'contact.report.privacy.info'),
    thankyou: getStaticFieldValue('contact.report', 'contact.report.thankyou'),
    error: getStaticFieldValue('contact.report', 'contact.report.error'),
  }

  return (
    <Container role="region" aria-label="Kontakt">
      <TracklineBackground track={1} bottom={'10%'} />
      <TracklineBackground track={4} right width={400} top={'0%'} />

      <Headline
        level={1}
        className="mb-6  text-3xl md:text-5xl lg:w-10/12 lg:mx-auto"
        ariaLabel={intl.formatMessage({ id: 'contact.headline' })}
      >
        {intl.formatMessage({ id: 'contact.headline' })}
      </Headline>
      <Copy className="lg:w-10/12 lg:mx-auto" html={copyContent} div />
      <ContactForm
        className="mt-4 lg:w-10/12 lg:mx-auto"
        infotexts={infotexts}
      />
    </Container>
  )
}

Impressum.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Impressum
